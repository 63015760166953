import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func' 

import User from '../../class/User'

import Moment from 'react-moment'
import 'moment/locale/fr'    

function Evaluations() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [avis, setAvis] = useState(false)

    const getAvis = async () => {

        let results = await User.avis()

        if(results.success){

            setAvis(results.data)

        }else{

            setAvis(results.message)

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        getAvis()

        document.title = "Mes évaluations - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">

                    <a href="" className='cl2 textUnd fz09'>Profil</a>
                    
                    <HeaderProfile />

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        <ProfileMenu data="evaluations"/>

                        <div className="p-ratings">

                        {

                            avis

                            ?

                                typeof avis === "string"

                                ?

                                <p className='fz09'>{ avis }</p>

                                :

                                <>
                                    
                                    {
                                    
                                    avis.map(av =>

                                        <div className="note bg6" key={ av.idA }>
                                            <div className="flex">
                                                <div className='user m_mb_20'>

                                                    <div className="av">
                                                    <img src={ Func.avatar(av.avatar) }/>
                                                    </div>

                                                    { av.name !== null && <Link to={ "/profile/" + av.idU } className="t cl2">{ av.name }</Link> }

                                                    { Func.note(av.note) }

                                                </div>

                                                <span className='date'><Moment locale="fr" fromNow>{ av.date }</Moment></span>

                                            </div>

                                            <p>{ av.text }</p>
                                            
                                        </div>

                                    )
                                    
                                    }

                                </>

                            :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="lds-dual-ring col"></div>
                            </div>

                        }

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default Evaluations