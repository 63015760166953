import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Paiements_Class from '../../class/Paiements_Class'
import Moment from 'react-moment';

function Identitee() {

    const navigate = useNavigate();

    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [data, setData] = useState(false)

    const [addIban, setAddIban] = useState(false)

    const [loading, setLoading] = useState(false)

    const userPaiements = async () => {

        let results = await Paiements_Class.kyc()

        if (results.success) {


            setData(results.data)

        } else {

            setFlash({ message: results.message, type: "error" })

        }

        window.scrollTo(0, 0)

    }

    const editKyc = async (e) => {

        e.preventDefault()

        setLoading(true)

        let inputs = e.target

        let firstname = inputs.firstname.value
        let lastname = inputs.lastname.value

        let type = inputs.type.value

        let file = inputs.file.files[0]

        if (file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
        if (file.size > 7340032) setFlash({ type: 'error', message: "Le fichier ne doit pas dépasser 7Mo" })

        let file_base64 = await toBase64(file)

        let results = await Paiements_Class.sendKyc(firstname, lastname, type, file_base64)

        console.log(results)

        if (results.success) {

            setFlash({ message: results.message, type: "succes" })

            userPaiements()

        } else {

            setFlash({ message: results.message, type: "error" })

        }

        window.scrollTo(0, 0)

        setLoading(false)

    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    useEffect(() => {

        Func.session(context, navigate)

        Func.getFlash(setFlash)

        userPaiements()

        document.title = "Mon identitée - " + APP_NAME

    }, [])

    return (

        <div>

            <Header />

            <div className="profil">

                <HeaderBox />

                <div className='content pos-r'>

                    <div className="c">

                        <a href="" className='cl2 textUnd fz09'>Mon identité</a>

                        <HeaderProfile />

                    </div>

                    <div className="mt30">

                        <div className="c pos-r">

                            <ProfileMenu data="porte-monnaie" />

                            {(flash !== '') && <p className={flash.type}>{flash.message}</p>}

                            {

                                data == false

                                    ?

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                    :

                                    <div className="paiements">

                                        <div className="bg6 p30 br10 mb20">

                                            <span className='d-block'>Identité (KYC) :

                                                {(data.active == '0') && ' Aucun document ajouté'}
                                                {(data.active == 'CREATED') && ' Demande non envoyée'}
                                                {(data.active == 'VALIDATION_ASKED') && ' En attente de validation'}
                                                {(data.active == 'VALIDATED') && <span className='cl4'> Document validé</span>}
                                                {(data.active == 'REFUSED') && <span style={{ color: 'red' }}> Document invalide, "<strong>{data.message == null ? data.type : data.message }</strong>"</span>}
                                                {(data.active == 'OUT_OF_DATE') && ' Date dépassée'}

                                            </span>

                                        </div>

                                        {

                                            data.active !== 'VALIDATED' && data.active !== 'VALIDATION_ASKED'

                                            &&

                                            <div className="bg6 p30 br10 mb20">

                                                <div>

                                                    <>

                                                        <form method='POST' className='gla-form bg6 p30 br10 mb20' onSubmit={(e) => editKyc(e)} id='parametres'>

                                                            <p className='mb30'>Nous te rappelons que la procédure de vérification d'identité (KYC) coûte 2 € à chaque tentative. C'est pourquoi il est essentiel de nous fournir des documents valides dès la première soumission.</p>

                                                            <h3 className='mb30 fw3'>Vérification KYC / IBAN</h3>

                                                            <label className='col-5'>
                                                                <span>Nom</span>
                                                                <div><input type='text' name='firstname' placeholder="Nom ..." required={true} maxLength={128} style={{ width: '300px', border: '1px solid rgba(118, 118, 118, 0.6)' }}/></div>
                                                            </label>

                                                            <label className='col-5'>
                                                                <span>Prénom</span>
                                                                <div><input type='text' name='lastname' placeholder="Prénom ..." required={true} maxLength={128} style={{ width: '300px', border: '1px solid rgba(118, 118, 118, 0.6)' }} /></div>
                                                            </label>

                                                            <label className='col-5'>
                                                                <span>Document</span>
                                                                <div><select name='type' style={{ border: '1px solid #666', width: '300px' }}>
                                                                    <option value='IDENTITY_PROOF'>Pièce d'identité / Passport / Carte de séjour</option>
                                                                </select></div>
                                                            </label>

                                                            <label className='col-5'>
                                                                <span>Fichier</span>
                                                                <div><input type='file' name='file' required={true} accept="image/jpeg,image/png,application/pdf" style={{ width: '300px' }} /></div>
                                                            </label>

                                                            <p className='fz08 mb10'>Formats acceptés :  PDF, JPEG, JPG, PNG</p>
                                                            <p className='fz08 mb20'>Taille document maximum : 7MB</p>
                                                            <p className='fz12'>Erreurs à éviter : <a href='https://docs.mangopay.com/guides/users/verification/documents/submission/id-best-practices#errors-to-avoid' target='_blank'>en cliquant ici</a></p>
                                                            
                                                            {

                                                                loading

                                                                    ?

                                                                    <div className="lds-dual-ring col"></div>

                                                                    :

                                                                    <div className="ta-end">
                                                                        <input type="submit" name="submit" value="Enregistrer" className="btn cl1 bg4 brc4 brc2solid fw5" />
                                                                    </div>

                                                            }

                                                        </form>

                                                    </>

                                                </div>
                                            </div>

                                        }

                                    </div>

                            }

                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div >

    )

}

export default Identitee