import React, { useState, useEffect, useContext, useRef } from 'react';

import { useNavigate, useParams, Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME, PHOTOS_ROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func' 

import Discussions from '../../class/Discussions'

import { DiscussionsCard, Message } from '../../components/profile-prive'

function Profile() {

    const { id } = useParams()

    const navigate = useNavigate()

    const context = useContext(Context)

    const [discussion, setDiscussion] = useState(false)
    const [annonces, setAnnonces] = useState(false)
    const [messages, setMessages] = useState(false)

    const scroll = useRef()

    const style = `footer{display: none}`

    const getDiscussion = async () => {

        setMessages(false)

        document.querySelectorAll('#messages_box .message').forEach(m => m.style.display = 'none')

        let results = await Discussions.discussion(id)
    
        if(results.success){

            console.log(results)
            
            setDiscussion(results.discussion)
            setAnnonces(results.annonces)
            setMessages(results.messages)

            setTimeout(() => {
                scroll.current.scrollIntoView({ behavior: "smooth" })
            }, 200)

        }else{

            setDiscussion(results.message)
            setMessages(results.message)

        }
    
    }

    const envoyerMessage = async (e, discussion_id) => {

        e.preventDefault()

        let inputs = e.target
    
        let message = inputs.message

        let message_val = ""
    
        if(message.value !== ""){

            message_val = message.value

            message.value = ""
        
            const results = await Discussions.envoyer_message(discussion_id, message_val)
        
            if(results.success){
        
                Func.envoyeMessageBox(message_val)

                scroll.current.scrollIntoView({ behavior: "smooth" })
        
            }else{

                alert(results.message)

            }
           
        }
       
    }

    useEffect(() => {

        setMessages(false)

        Func.session(context, navigate)
        
        getDiscussion()

        document.title = "Ma Discussions - " + APP_NAME

    }, [id])

    return (

        <div>

        <Header />
                
            <div>

            <HeaderBox />

            <div className='pos-r discussions_page'>

                <div className="c flex">

                    <DiscussionsCard discussion_id={ id }/>

                    {

                        discussion

                        ?

                        <div className='col-7 p20 m_p0 m_mt_10'>

                            <div className='user mb20 m_flex ai-center'>

                                <span className='icon mr10 fz15 pc_d_none' onClick={e => navigate(-1)}>v</span>

                                {
                                    annonces

                                    &&

                                    annonces.map(annonce => 
                                    
                                        <>
                                            <div className="av">
                                                <img src={PHOTOS_ROOT + 'small/' + annonce.name + ".jpg"} />
                                            </div>

                                            <Link to={'/annonce/' + annonce.idA} className="t cl2 mr10">{ annonce.titre }</Link>
                                        </>
                                        
                                    )

                                }

                            </div>

                            {

                                messages

                                ?

                                    typeof messages == 'object'

                                        ?

                                        <div className='messages' id='messages_box'>

                                            { messages.map(element => <Message data={element} id={discussion.id} annonce_id={discussion.annc_id} key={ element.i }/>) }

                                            <span ref={scroll}></span>

                                        </div>

                                        :
   
                                        <div className='messages'>
                                            <p className='fz08'>{messages}</p>
                                        </div>

                                :

                                <div className='messages'>

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                </div>

                            }

                            <form className='send_message flex gla-form bg7 br8 p10 mt20 m_flex' onSubmit={(e) => envoyerMessage(e, discussion.id)} autoComplete='off'>

                                <input type="text" name='message' placeholder="Mon message ..." />
                                <input type="submit" name='submit' value="Envoyer" className='btn cl4 brc1solid bg7 brc7'/>

                            </form>

                        </div>

                        :

                        <div className='flex jc-center mt50 mb50 col-7 pt100'>
                            <div className="lds-dual-ring col"></div>
                        </div>

                    }

                </div>

            </div>

            </div>

            <style>{style}</style>

            <Footer/>

        </div>

    )

}

export default Profile